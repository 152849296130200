import React from 'react';
import '../styles/components/_components.hero.scss';

const Hero = ({ title, callout, extra, secondary = false, small = false }) => {
  return (
    <div className={`c-hero ${secondary ? 'c-hero--secondary' : ''}`}>
      <span className="c-hero__bg" />
      <div className="c-hero__content o-wrapper">
        <div className="c-hero__title">
          {typeof title === 'string' ? (
            <h1
              className={`o-hugeTitle u-10/12@desktop u-push-1/12@desktop ${
                !secondary ? 'o-hugeTitle--light' : ''
              }`}
            >
              {title}
            </h1>
          ) : (
            title
          )}
        </div>
        <div className={`c-hero__callout ${small ? 'c-hero__callout--small ' : ''}`}>
          {typeof callout === 'string' ? (
            <p
              className={`o-callout u-10/12@desktop u-push-1/12@desktop ${
                !secondary ? 'o-callout--light' : ''
              }`}
              dangerouslySetInnerHTML={{ __html: callout }}
            />
          ) : (
            callout
          )}
        </div>
        {extra}
      </div>
    </div>
  );
};

export default Hero;
